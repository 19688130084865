*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}
html {
  font-size: 62.5%;
}
@media only screen and (max-width: 75em) {
  html {
    font-size: 56.25%;
  }
}
@media only screen and (max-width: 56.25em) {
  html {
    font-size: 50%;
  }
}
@media only screen and (min-width: 112.5em) {
  html {
    font-size: 75%;
  }
}
@media only screen and (max-width: 400px) {
  html {
    font-size: 48%;
  }
}
body {
  box-sizing: border-box;
  padding: 3rem;
}
@media only screen and (max-width: 56.25em) {
  body {
    padding: 0;
  }
}
@media only screen and (max-width: 375px) {
  body {
    padding: 0;
  }
}
::selection {
  background-color: #55c57a;
  color: #fff;
}
body {
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  line-height: 1.7;
  color: #777;
}
