.container__wp {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #eee;
  transition: all 0.7s ease-in;
}

.wp-box {
  position: fixed;
  top: 6rem;
  left: 87%;
  z-index: 9999999999;
}

.wp-number:link .wp-number:visited {
  color: #f7f7f7;
  background-color: #333;
  text-decoration: none;
  display: inline-block;
  transition: all 0.2s;
}
