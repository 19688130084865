.section-about {
  background-color: #f7f7f7;
  padding: 25rem 0;
  margin-top: -20vh;
}
@media only screen and (max-width: 56.25em) {
  .section-about {
    padding: 20rem 0;
  }
}
