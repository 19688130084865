.footer {
  background-color: #333;
  padding: 10rem 0;
  font-size: 1.4rem;
  color: #f7f7f7;
}
@media only screen and (max-width: 56.25em) {
  .footer {
    padding: 8rem 0;
  }
}
.footer__logo-box {
  text-align: center;
  margin-bottom: 8rem;
}
@media only screen and (max-width: 56.25em) {
  .footer__logo-box {
    margin-bottom: 6rem;
  }
}
.footer__logo {
  width: 15rem;
  height: auto;
}
.footer__navigation {
  position: relative;
  border-top: 1px solid #777;
  width: 100%;
  padding-top: 2rem;
  display: flex;
}
@media only screen and (max-width: 1800px) {
  .footer__navigation {
    width: 125%;
    text-align: left;
  }
}
@media only screen and (max-width: 1200px) {
  .footer__navigation {
    width: 115%;
    text-align: center;
  }
  .contact {
    position: relative;
    left: 10%;
  }
}
@media only screen and (max-width: 400px) {
  .contact {
    left: 0;
  }
}
@media only screen and (max-width: 920px) {
  .footer__navigation {
    width: fit-content;
    display: grid;
  }
  .contact {
    position: relative;
    margin-top: 30%;
  }
}
.footer__list {
  position: relative;
  list-style: none;
  left: 28%;
  display: grid;
}

@media only screen and (max-width: 60.5em) {
  .footer__list {
    position: relative;
    list-style: none;
    left: 3%;
    max-width: max-content;
  }
}
@media only screen and (min-width: 65.5em) {
  .footer__list {
    position: relative;
    list-style: none;
    left: 28%;
  }
}
.footer__item {
  display: flex;
}
.footer__item:not(:last-child) {
  margin-right: 1.5rem;
}
.footer__link:link,
.footer__link:visited {
  color: #f7f7f7;
  background-color: #333;
  text-decoration: none;
  display: inline-block;
  transition: all 0.2s;
}
.footer__link:hover,
.footer__link:active {
  color: #55c57a;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.4);
  -webkit-transform: rotate(5deg) scale(1.3);
  transform: rotate(5deg) scale(1.3);
}
.footer__copyright {
  border-top: 1px solid #777;
  padding-top: 2rem;
  width: 70%;
  float: right;
}
@media only screen and (max-width: 56.25em) {
  .footer__copyright {
    width: 86%;
    float: none;
  }
}

.footer-row {
  max-width: 114rem;
  margin: 0 auto;
}
.footer-row:not(:last-child) {
  margin-bottom: 8rem;
}
@media only screen and (max-width: 56.25em) {
  .footer-row:not(:last-child) {
    margin-bottom: 6rem;
  }
}
@media only screen and (max-width: 56.25em) {
  .footer-row {
    max-width: 50rem;
    padding: 0 3rem;
  }
}
.footer-row::after {
  content: '';
  display: table;
  clear: both;
}
.footer-row [class^='col-'] {
  float: left;
}
.footer-row [class^='col-']:not(:last-child) {
  margin-right: 6rem;
}
@media only screen and (max-width: 56.25em) {
  .footer-row [class^='col-']:not(:last-child) {
    margin-right: 0;
    margin-bottom: 6rem;
  }
}
@media only screen and (max-width: 56.25em) {
  .footer-row [class^='col-'] {
    width: 100% !important;
  }
}
.footer-row .col-1-of-2 {
  width: calc((100% - 6rem) / 2);
}
.contact_us {
  left: 0;
  position: relative;
  display: flex;
}
.map-container {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}
