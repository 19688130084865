.product-image-container {
  position: relative;
  display: flex;
  height: 40%;
  width: 33%;
}
.image__properties {
  position: relative;
  height: 100%;
  width: 100%;
  border-radius: 50%;
}
.circle {
  position: relative;
  height: 20rem;
  width: 20rem;
  border-radius: 50%;
}

@media only screen and (max-width: 350px) {
  .circle {
    position: relative;
    height: 10rem;
    width: 10rem;
    border-radius: 50%;
  }
}

.product-image-container .col-1-of-4 {
  width: calc((100% - 5 * 6rem) / 6);
  display: flex;
  position: relative;
}
.product-name {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  height: 20rem;
  width: 20rem;
  border-radius: 50%;
}
.product-name:hover {
  opacity: 1;
  background-image: linear-gradient(
    to right bottom,
    rgba(126, 213, 111, 0.8),
    rgba(40, 180, 133, 0.8)
  );
}
.product-name-position {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #fff;
  font-size: 2rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.2rem;
  line-height: 1.2;
  padding: 1rem;
  z-index: 10;
}

.product-row {
  /* position: relative; */
  max-width: 100%;
  margin: 0 auto;
  z-index: 2;
}
@media only screen and (max-width: 1227px) {
  .product-row {
    max-width: 88rem;
  }
}

@media only screen and (max-width: 540px) {
  .product-row {
    max-width: 45rem;
  }
}

@media only screen and (max-width: 350px) {
  .product-row {
    max-width: 29rem;
  }
  .product-name {
    width: 10rem;
    height: 10rem;
  }
  .product-name-position {
    font-size: 1rem;
    font-weight: 700;
  }
}

.product-row:not(:last-child) {
  margin-bottom: 8rem;
}
@media only screen and (max-width: 56.25em) {
  .product-row:not(:last-child) {
    margin-bottom: 6rem;
  }
}

.product-row::after {
  content: '';
  display: table;
  clear: both;
}
.product-row [class^='col-'] {
  float: left;
  margin-left: 2rem;
}
/* .product-row [class^='col-']:not(:last-child) {
  margin: 4rem;
} */
@media only screen and (max-width: 56.25em) {
  .product-row [class^='col-']:not(:last-child) {
    margin-bottom: 6rem;
  }
}
/* @media only screen and (max-width: 56.25em) {
  .product-row [class^='col-'] {
    width: 100% !important;
  }
} */
