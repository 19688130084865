.section-tours {
  background-color: #f7f7f7;
  padding: 25rem 0 15rem 0;
  margin-top: -30rem;
  z-index: 1;
}
@media only screen and (max-width: 56.25em) {
  .section-tours {
    /* padding: 20rem 0 10rem 0; */
  }
}

.card-row {
  position: relative;
  max-width: 165rem;
  margin: 0 auto;
  z-index: 2;
}
.card-row:not(:last-child) {
  margin-bottom: 8rem;
}
@media only screen and (max-width: 56.25em) {
  .card-row:not(:last-child) {
    margin-bottom: 6rem;
  }
}
@media only screen and (max-width: 56.25em) {
  .card-row {
    max-width: 50rem;
    /* padding: 0 3rem; */
  }
}
.card-row::after {
  content: '';
  display: table;
  clear: both;
}
.card-row [class^='col-'] {
  float: left;
}
.card-row [class^='col-']:not(:last-child) {
  margin-right: 6rem;
  margin-bottom: 6rem;
}
@media only screen and (max-width: 56.25em) {
  .card-row [class^='col-']:not(:last-child) {
    margin-right: 0;
    margin-bottom: 6rem;
  }
}
@media only screen and (max-width: 56.25em) {
  .card-row [class^='col-'] {
    width: 100% !important;
  }
}
