.u-center-text {
  text-align: center !important;
}
.u-margin-bottom-big {
  margin-bottom: 8rem !important;
}
@media only screen and (max-width: 56.25em) {
  .u-margin-bottom-big {
    margin-bottom: 5rem !important;
  }
}
.heading-secondary {
  font-size: 3.5rem;
  text-transform: uppercase;
  font-weight: 700;
  display: inline-block;
  background-image: linear-gradient(to right, #7ed56f, #28b485);
  color: transparent;
  letter-spacing: 0.2rem;
  transition: all 0.2s;
}
@media only screen and (max-width: 56.25em) {
  .heading-secondary {
    font-size: 3rem;
  }
}
@media only screen and (max-width: 37.5em) {
  .heading-secondary {
    font-size: 2.5rem;
  }
}
.heading-secondary:hover {
  -webkit-transform: skewY(2deg) skewX(15deg) scale(1.1);
  transform: skewY(2deg) skewX(15deg) scale(1.1);
  text-shadow: 0.5rem 1rem 2rem rgba(0, 0, 0, 0.2);
}

.card-row .col-1-of-2 {
  width: calc((100% - 6rem) / 2);
}
.card-row .col-1-of-3 {
  width: calc((100% - 2 * 9rem) / 3);
}
.card-row .col-1-of-4 {
  width: calc((100% - 3 * 8rem) / 4);
}
.card-row .col-2-of-3 {
  width: calc(2 * ((100% - 2 * 6rem) / 3) + 6rem);
}
.card-row .col-2-of-4 {
  width: calc(2 * ((100% - 3 * 6rem) / 4) + 6rem);
}
.card-row .col-3-of-4 {
  width: calc(3 * ((100% - 3 * 6rem) / 4) + 2 * 6rem);
}
.row .col-1-of-6 {
  width: calc((100% - 5 * 6rem) / 6);
}
.card {
  -webkit-perspective: 150rem;
  perspective: 150rem;
  -moz-perspective: 150rem;
  position: relative;
  height: 52rem;
}
.card__side {
  height: 52rem;
  transition: all 0.8s ease;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-radius: 3px;
  overflow: hidden;
  box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.15);
}
.card__side--front {
  background-color: #fff;
}

.card__picture {
  position: relative;
  background-size: cover;
  height: 23rem;
  background-blend-mode: screen;
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
  clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
.card__picture--0 {
  background-image: linear-gradient(to right bottom, #7ed56f, #28b485),
    url(../../../../public/img/4-ürünler1.jpg);
}
.card__picture--1 {
  background-image: linear-gradient(to right bottom, #7ed56f, #28b485),
    url(../../../../public/img/4-ürünler2.jpg);
}
.card__picture--2 {
  background-image: linear-gradient(to right bottom, #7ed56f, #28b485),
    url(../../../../public/img/1-fideTesisi1.jpg);
}

.card__heading {
  font-size: 2.8rem;
  font-weight: 300;
  text-transform: uppercase;
  text-align: right;
  color: #fff;
  position: absolute;
  top: 12rem;
  right: 2rem;
  width: 75%;
}
.card__heading-span {
  padding: 1rem 1.5rem;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
}
.card__heading-span--1 {
  background-image: linear-gradient(
    to right bottom,
    rgba(255, 185, 0, 0.85),
    rgba(255, 119, 48, 0.85)
  );
}
.card__heading-span--2 {
  background-image: linear-gradient(
    to right bottom,
    rgba(126, 213, 111, 0.85),
    rgba(40, 180, 133, 0.85)
  );
}
.card__heading-span--3 {
  background-image: linear-gradient(
    to right bottom,
    rgba(41, 152, 255, 0.85),
    rgba(86, 67, 250, 0.85)
  );
}
.card__details {
  padding: 3rem;
}
.card__details ul {
  list-style: none;
  width: 100%;
  margin: 0 auto;
}
.card__details ul li {
  text-align: center;
  font-size: 1.5rem;
  padding: 1rem;
}
.card__details ul li:not(:last-child) {
  border-bottom: 1px solid #eee;
}
.card__cta {
  position: absolute;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 90%;
  text-align: center;
  bottom: -15px;
}
.card__price-box {
  text-align: center;
  color: #fff;
  margin-bottom: 8rem;
}
.card__price-only {
  font-size: 1.4rem;
  text-transform: uppercase;
}
.card__price-value {
  font-size: 6rem;
  font-weight: 100;
}
@media only screen and (max-width: 56.25em), only screen and (hover: none) {
  .card {
    height: auto;
    border-radius: 3px;
    background-color: #fff;
    box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.15);
  }
  .card__side {
    height: auto;
    position: relative;
    box-shadow: none;
  }
  .card__side--back {
    -webkit-transform: rotateY(0);
    transform: rotateY(0);
    -webkit-clip-path: polygon(0 15%, 100% 0, 100% 100%, 0% 100%);
    clip-path: polygon(0 15%, 100% 0, 100% 100%, 0% 100%);
  }
  .card:hover .card__side--front {
    -webkit-transform: rotateY(0);
    transform: rotateY(0);
  }
  .card__details {
    padding: 1rem 3rem;
  }
  .card__cta {
    position: relative;
    top: 0%;
    left: 0;
    -webkit-transform: translate(0);
    transform: translate(0);
    width: 100%;
    padding: 7rem 4rem 4rem 4rem;
  }
  .card__price-box {
    margin-bottom: 3rem;
  }
  .card__price-value {
    font-size: 4rem;
  }
}
