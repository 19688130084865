.col-1-of-2 {
  width: calc((100% - 6rem) / 2);
}
@media only screen and (max-width: 1080px) {
  .col-1-of-2 {
    position: relative;
  }
}
@media only screen and (max-width: 900px) {
  .col-1-of-2 {
    position: relative;
  }
}
@media only screen and (max-width: 800px) {
  .col-1-of-2 {
    position: relative;
  }
}

@media only screen and (max-width: 400px) {
  .col-1-of-2 {
    position: relative;
  }
}
