.row {
  max-width: 114rem;
  margin: 0 auto;
}
.row:not(:last-child) {
  margin-bottom: 8rem;
}

.row::after {
  content: '';
  display: table;
  clear: both;
}
.row [class^='col-'] {
  float: left;
}
.row [class^='col-']:not(:last-child) {
  margin-left: 6rem;
}
@media only screen and (max-width: 900px) {
  .row [class^='col-']:not(:last-child) {
    margin-left: 0;
  }
}

.row .col-1-of-2 {
  width: calc((100% - 6rem) / 2);
  bottom: 3rem;
}
.row .col-1-of-3 {
  width: calc((100% - 2 * 6rem) / 3);
}
.row .col-2-of-3 {
  width: calc(2 * ((100% - 2 * 6rem) / 3) + 6rem);
}
.row .col-1-of-4 {
  width: calc((100% - 3 * 6rem) / 4);
}
.row .col-2-of-4 {
  width: calc(2 * ((100% - 3 * 6rem) / 4) + 6rem);
}
.row .col-3-of-4 {
  width: calc(3 * ((100% - 3 * 6rem) / 4) + 2 * 6rem);
}
.row .col-1-of-6 {
  width: calc((100% - 5 * 6rem) / 6);
}
.row__title {
  position: relative;
  left: 50%;
  top: 0;
}
@media only screen and (max-width: 900px) {
  .row:not(:last-child) {
    margin-bottom: 6rem;
  }
}
@media only screen and (max-width: 900px) {
  .row {
    max-width: 65rem;
    padding: 0 8%;
  }
}
@media only screen and (max-width: 500px) {
  .row {
    max-width: 65rem;
    padding: 5%;
  }
}
@media only screen and (max-width: 450px) {
  .row {
    max-width: 65rem;
    padding: 2%;
  }
}
@media only screen and (max-width: 400px) {
  .row {
    max-width: 54rem;
  }
}
@media only screen and (max-width: 370px) {
  .row {
    max-width: 54rem;
  }
}
@media only screen and (max-width: 56.25em) {
  .row [class^='col-']:not(:last-child) {
    margin-right: 0;
    margin-bottom: 6rem;
  }
}
@media only screen and (max-width: 56.25em) {
  .row [class^='col-'] {
    width: 100% !important;
  }
}
